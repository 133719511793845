import { default as indexw01B8QzRAlMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/index.vue?macro=true";
import { default as index8EAtMTzgVLMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/login/index.vue?macro=true";
import { default as indexYxBR0DTCOoMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/magic-link/index.vue?macro=true";
import { default as not_45yetQHPEK3LLE0Meta } from "/codebuild/output/src1477681507/src/zazos-web/pages/not-yet.vue?macro=true";
import { default as indexMOMOxy7v5wMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/reset-password/index.vue?macro=true";
import { default as set_45workspaceluMPreQS0OMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/set-workspace.vue?macro=true";
import { default as indexrYNpPjFdVjMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/sign-up/index.vue?macro=true";
import { default as _91appName_93OURxKlToyYMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/store/apps/[appName].vue?macro=true";
import { default as indexFXqwOC2p15Meta } from "/codebuild/output/src1477681507/src/zazos-web/pages/store/index.vue?macro=true";
import { default as indexxVQaqch6KfMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue?macro=true";
import { default as indexHZ8DmW77auMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/index.vue?macro=true";
import { default as indexaVpNlffok2Meta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue?macro=true";
import { default as indexnwWbQL8wy0Meta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/login/index.vue?macro=true";
import { default as indexA3synuuZXWMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue?macro=true";
import { default as indexlOMzYq0VCPMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as newLIGYN6NwGFMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue?macro=true";
import { default as account_45configurationUhHgqri4c5Meta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue?macro=true";
import { default as indexErtie0HsaNMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/index.vue?macro=true";
import { default as m95dxVtFNcvMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m.vue?macro=true";
import { default as indexSF0AUNn9GPMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue?macro=true";
import { default as index4S7h6DbWINMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue?macro=true";
import { default as indexckAFWKlx8NMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/p/index.vue?macro=true";
import { default as reset_45passwordtOpPJ0LsZXMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue?macro=true";
import { default as indexHCLX6poGKyMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue?macro=true";
import { default as indexDxeo66uCExMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue?macro=true";
import { default as historyy24n4mpnfNMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue?macro=true";
import { default as indexh2DsMLGj17Meta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue?macro=true";
import { default as indexPxCgUjLovkMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue?macro=true";
import { default as importURZKb2rkMhMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue?macro=true";
import { default as indexYQH5tNbdxaMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue?macro=true";
import { default as schemaaTzZKiUkjtMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue?macro=true";
import { default as index81HBDDnQU8Meta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue?macro=true";
import { default as indexErgM7hni3fMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue?macro=true";
import { default as _91id_93VuBYm3HvMYMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue?macro=true";
import { default as indexNDgdOLnH5LMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue?macro=true";
import { default as index9DH0ssK56LMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue?macro=true";
import { default as indexlSArinxQCzMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue?macro=true";
import { default as indexaMtxIezH4GMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue?macro=true";
import { default as indexW8knjO4hRDMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue?macro=true";
import { default as _91id_93ANDvNp32T1Meta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue?macro=true";
import { default as index76nwiVM3X3Meta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue?macro=true";
import { default as integrationsLaxRmjzKGpMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue?macro=true";
import { default as indexvPCUJuC1PIMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue?macro=true";
import { default as indexT0mIcyNnSuMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue?macro=true";
import { default as indexdNdSnCfZ0tMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue?macro=true";
import { default as _91id_93sKxMg9jDF2Meta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue?macro=true";
import { default as indexUVugN7Oaa5Meta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue?macro=true";
import { default as new90XTOOliPyMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue?macro=true";
import { default as user_45groupsBlOxAAs77BMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue?macro=true";
import { default as indexTOepRFCCmAMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue?macro=true";
import { default as settings8CTVKE2UXNMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue?macro=true";
import { default as studioWirJw8bIBuMeta } from "/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/en",
    meta: indexw01B8QzRAlMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/index.vue")
  },
  {
    name: "index___pt",
    path: "/",
    meta: indexw01B8QzRAlMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "login___pt",
    path: "/login",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/login/index.vue")
  },
  {
    name: "magic-link___en",
    path: "/en/magic-link",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "magic-link___pt",
    path: "/magic-link",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/magic-link/index.vue")
  },
  {
    name: "not-yet___en",
    path: "/en/not-yet",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "not-yet___pt",
    path: "/not-yet",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/not-yet.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "reset-password___pt",
    path: "/reset-password",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/reset-password/index.vue")
  },
  {
    name: "set-workspace___en",
    path: "/en/set-workspace",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "set-workspace___pt",
    path: "/set-workspace",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/set-workspace.vue")
  },
  {
    name: "sign-up___en",
    path: "/en/sign-up",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "sign-up___pt",
    path: "/sign-up",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/sign-up/index.vue")
  },
  {
    name: "store-apps-appName___en",
    path: "/en/store/apps/:appName()",
    meta: _91appName_93OURxKlToyYMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store-apps-appName___pt",
    path: "/store/apps/:appName()",
    meta: _91appName_93OURxKlToyYMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/store/apps/[appName].vue")
  },
  {
    name: "store___en",
    path: "/en/store",
    meta: indexFXqwOC2p15Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "store___pt",
    path: "/store",
    meta: indexFXqwOC2p15Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/store/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___en",
    path: "/en/t/:tenantSlug()/email-validation",
    meta: indexxVQaqch6KfMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug-email-validation___pt",
    path: "/t/:tenantSlug()/email-validation",
    meta: indexxVQaqch6KfMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/email-validation/index.vue")
  },
  {
    name: "t-tenantSlug___en",
    path: "/en/t/:tenantSlug()",
    meta: indexHZ8DmW77auMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug___pt",
    path: "/t/:tenantSlug()",
    meta: indexHZ8DmW77auMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/index.vue")
  },
  {
    name: "t-tenantSlug-loading___en",
    path: "/en/t/:tenantSlug()/loading",
    meta: indexaVpNlffok2Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-loading___pt",
    path: "/t/:tenantSlug()/loading",
    meta: indexaVpNlffok2Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/loading/index.vue")
  },
  {
    name: "t-tenantSlug-login___en",
    path: "/en/t/:tenantSlug()/login",
    meta: indexnwWbQL8wy0Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: "t-tenantSlug-login___pt",
    path: "/t/:tenantSlug()/login",
    meta: indexnwWbQL8wy0Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/login/index.vue")
  },
  {
    name: m95dxVtFNcvMeta?.name,
    path: "/en/t/:tenantSlug()/m",
    meta: m95dxVtFNcvMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___en",
    path: ":viewId()",
    meta: indexA3synuuZXWMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___en",
    path: ":viewId()/r/:recordId()",
    meta: indexlOMzYq0VCPMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___en",
    path: ":viewId()/r/new",
    meta: newLIGYN6NwGFMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___en",
    path: "account-configuration",
    meta: account_45configurationUhHgqri4c5Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___en",
    path: "",
    meta: indexErtie0HsaNMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: m95dxVtFNcvMeta?.name,
    path: "/t/:tenantSlug()/m",
    meta: m95dxVtFNcvMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m.vue"),
    children: [
  {
    name: "t-tenantSlug-m-viewId___pt",
    path: ":viewId()",
    meta: indexA3synuuZXWMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-recordId___pt",
    path: ":viewId()/r/:recordId()",
    meta: indexlOMzYq0VCPMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-m-viewId-r-new___pt",
    path: ":viewId()/r/new",
    meta: newLIGYN6NwGFMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/[viewId]/r/new.vue")
  },
  {
    name: "t-tenantSlug-m-account-configuration___pt",
    path: "account-configuration",
    meta: account_45configurationUhHgqri4c5Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/account-configuration.vue")
  },
  {
    name: "t-tenantSlug-m___pt",
    path: "",
    meta: indexErtie0HsaNMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/m/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-p-viewId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()",
    meta: indexSF0AUNn9GPMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId___pt",
    path: "/t/:tenantSlug()/p/:viewId()",
    meta: indexSF0AUNn9GPMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___en",
    path: "/en/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: index4S7h6DbWINMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p-viewId-r-recordId___pt",
    path: "/t/:tenantSlug()/p/:viewId()/r/:recordId()",
    meta: index4S7h6DbWINMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/p/[viewId]/r/[recordId]/index.vue")
  },
  {
    name: "t-tenantSlug-p___en",
    path: "/en/t/:tenantSlug()/p",
    meta: indexckAFWKlx8NMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-p___pt",
    path: "/t/:tenantSlug()/p",
    meta: indexckAFWKlx8NMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/p/index.vue")
  },
  {
    name: "t-tenantSlug-reset-password___en",
    path: "/en/t/:tenantSlug()/reset-password",
    meta: reset_45passwordtOpPJ0LsZXMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-reset-password___pt",
    path: "/t/:tenantSlug()/reset-password",
    meta: reset_45passwordtOpPJ0LsZXMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/reset-password.vue")
  },
  {
    name: "t-tenantSlug-sign-up___en",
    path: "/en/t/:tenantSlug()/sign-up",
    meta: indexHCLX6poGKyMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sign-up___pt",
    path: "/t/:tenantSlug()/sign-up",
    meta: indexHCLX6poGKyMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/sign-up/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___en",
    path: "/en/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: "t-tenantSlug-sso-saml___pt",
    path: "/t/:tenantSlug()/sso/saml",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/sso/saml/index.vue")
  },
  {
    name: studioWirJw8bIBuMeta?.name,
    path: "/en/t/:tenantSlug()/studio",
    meta: studioWirJw8bIBuMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___en",
    path: "automations/:id()/history",
    meta: historyy24n4mpnfNMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___en",
    path: "automations/:id()",
    meta: indexh2DsMLGj17Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___en",
    path: "automations",
    meta: indexPxCgUjLovkMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___en",
    path: "data/:id()/import",
    meta: importURZKb2rkMhMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___en",
    path: "data/:id()",
    meta: indexYQH5tNbdxaMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___en",
    path: "data/:id()/schema",
    meta: schemaaTzZKiUkjtMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___en",
    path: "data",
    meta: index81HBDDnQU8Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___en",
    path: "",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___en",
    path: "pages/:id()",
    meta: _91id_93VuBYm3HvMYMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___en",
    path: "pages",
    meta: indexNDgdOLnH5LMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settings8CTVKE2UXNMeta?.name,
    path: "settings",
    meta: settings8CTVKE2UXNMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___en",
    path: "app-builder",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___en",
    path: "authentication",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___en",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___en",
    path: "",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsLaxRmjzKGpMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___en",
    path: "",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___en",
    path: "internal",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___en",
    path: "system-entities",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___en",
    path: "tokens",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupsBlOxAAs77BMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___en",
    path: ":id()",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___en",
    path: "",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___en",
    path: "new",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___en",
    path: "users",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  },
  {
    name: studioWirJw8bIBuMeta?.name,
    path: "/t/:tenantSlug()/studio",
    meta: studioWirJw8bIBuMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-automations-id-history___pt",
    path: "automations/:id()/history",
    meta: historyy24n4mpnfNMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/history.vue")
  },
  {
    name: "t-tenantSlug-studio-automations-id___pt",
    path: "automations/:id()",
    meta: indexh2DsMLGj17Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/automations/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-automations___pt",
    path: "automations",
    meta: indexPxCgUjLovkMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/automations/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-import___pt",
    path: "data/:id()/import",
    meta: importURZKb2rkMhMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/import.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id___pt",
    path: "data/:id()",
    meta: indexYQH5tNbdxaMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/index.vue")
  },
  {
    name: "t-tenantSlug-studio-data-id-schema___pt",
    path: "data/:id()/schema",
    meta: schemaaTzZKiUkjtMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/[id]/schema.vue")
  },
  {
    name: "t-tenantSlug-studio-data___pt",
    path: "data",
    meta: index81HBDDnQU8Meta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/data/index.vue")
  },
  {
    name: "t-tenantSlug-studio___pt",
    path: "",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/index.vue")
  },
  {
    name: "t-tenantSlug-studio-pages-id___pt",
    path: "pages/:id()",
    meta: _91id_93VuBYm3HvMYMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/pages/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-pages___pt",
    path: "pages",
    meta: indexNDgdOLnH5LMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/pages/index.vue")
  },
  {
    name: settings8CTVKE2UXNMeta?.name,
    path: "settings",
    meta: settings8CTVKE2UXNMeta || {},
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-app-builder___pt",
    path: "app-builder",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/app-builder/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-authentication___pt",
    path: "authentication",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/authentication/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-global-parameters-manager___pt",
    path: "global-parameters-manager",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/global-parameters-manager/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings___pt",
    path: "",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/index.vue")
  },
  {
    name: integrationsLaxRmjzKGpMeta?.name,
    path: "integrations",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-integrations-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-integrations___pt",
    path: "",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/integrations/index.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-internal___pt",
    path: "internal",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/internal/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-system-entities___pt",
    path: "system-entities",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/system-entities/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-tokens___pt",
    path: "tokens",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/tokens/index.vue")
  },
  {
    name: user_45groupsBlOxAAs77BMeta?.name,
    path: "user-groups",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups.vue"),
    children: [
  {
    name: "t-tenantSlug-studio-settings-user-groups-id___pt",
    path: ":id()",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/[id].vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups___pt",
    path: "",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/index.vue")
  },
  {
    name: "t-tenantSlug-studio-settings-user-groups-new___pt",
    path: "new",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/user-groups/new.vue")
  }
]
  },
  {
    name: "t-tenantSlug-studio-settings-users___pt",
    path: "users",
    component: () => import("/codebuild/output/src1477681507/src/zazos-web/pages/t/[tenantSlug]/studio/settings/users/index.vue")
  }
]
  }
]
  }
]